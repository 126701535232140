<template>
    <div class="login-box" @click="cancelLogin">
        <div class="login-form abs-center">
            <el-card>
            <div>
                <div class="align-center">
                    <img class="login-title" src="@/assets/img/login-title.png"/>
                </div>
                <div class="align-center">
                    <img class="login-title-note" src="@/assets/img/login-title-note.png"/>
                </div>

                <div @click.stop="">
                    <div class="align-center input-box phone-box">
                        <el-input v-model="info.phone" placeholder="输入手机号"></el-input>
                    </div>
                    <div class="align-center input-box code-box rel">

                        <el-input v-model="info.code"  :disabled="tmpSecond==maxSecond" placeholder="输入验证码"></el-input>
                        <div class="timer-box" @click="toShowCaptche">
                            {{ txt }}
                        </div>
                    </div>
                     
                    <div class="align-center input-box dologin-box">

                        <el-button @click="doLogin">登录</el-button>
                    </div>
                    
                    <div class="align-center input-box agree-box">
                        <el-checkbox v-model="agree">我已阅读并同意
                            <span class="main-color">《服务协议》</span>及
                            <span class="main-color">《隐私政策》</span></el-checkbox>
                    </div>
                    
                </div>


                <div class="align-center" style="margin-top: 80px;">
                    新用户登录即视为已注册  
                </div>
                
                
                
            </div>
                
            </el-card>
        </div>

        <div v-show="showCaptche" class="login-form abs-center" @click.stop="">
            <el-card>
                <el-form label-position="top" label-width="80px" :model="info">
                    <div>
                        <img :src="captchaContent" style="width: 100%;"/>
                    </div>
                    
                    <div class="align-center input-box">
                        
                        <el-input placeholder="请输入验证码" v-model="info.captchaCode">
                        </el-input>
                    </div>
                    
                    <div class="align-center input-box captcha-btn-box">
                        <div>
                            <el-button @click="sendPhoneCode">确定</el-button>
                        </div>
                        <div>
                            <el-button class="captcha-cancel" @click="showCaptche=!showCaptche">取消</el-button>
                    
                        </div>
                        
                    </div>
                </el-form>
            </el-card>
        </div>
    </div>
</template>

<script>
import conf from '@/assets/js/conf';
export default{
    data(){
        return {
            info:{
                phone:"",
                code:"",
                passwd:"",
                codeKey:"",
                captchaCode:""
            },
            txt:"发送验证码",
            maxSecond:120,
            tmpSecond:120,
            showCaptche:false,
            captchaContent:"",
            timer:null,
            agree:true
        }
    },
    methods:{
        toShowCaptche(){
            if(this.tmpSecond<this.maxSecond){
                return false
            }

            // 先检查手机号
            if(!conf.checkPhone(this.info.phone)){
                this.$message.error("手机号错误")
                return false
            }
            var _this = this

            this.$axios({
                method:"post",
                url:conf.api.createCaptcha,
                data:{
                    phone:this.info.phone
                }
            }).then(res=>{
                console.log(res)
                if(res.status==200 && res.data.code==1){
                    _this.captchaContent = res.data.data.content
                    _this.info.codeKey = res.data.data.key
                    _this.showCaptche = true     
                }
                
            }).catch(err=>{
                console.log(err)

            })
            
        },
        // 发送验证码
        sendPhoneCode(){
            var _this = this

            this.$axios({
                method:"post",
                url:conf.api.sendPhoneCode,
                data:{
                    phone:this.info.phone,
                    code:this.info.captchaCode
                }
            }).then(res=>{
                console.log(res)
                if(res.status==200){
                    if(res.data.code==1){
                        _this.showCaptche=false
                        // 开启倒计时
                        _this.tmpSecond = _this.maxSecond
                        _this.timer = setInterval(function(){
                            _this.tmpSecond--

                         _this.txt="剩余"+_this.tmpSecond+"秒"   
                         if(_this.tmpSecond<=0){
                                clearInterval(_this.timer)
                                _this.tmpSecond = _this.maxSecond
                                _this.txt="发送验证码"   
                            }

                            
                        },1000)
                    }else{
                        _this.$message.error(res.data.msg)
                    }
                }else{
                    _this.$message.error("请求错误")
                }
            }).catch(err=>{
                console.log(err)
            })
        },
        doLogin(){
            if(!conf.checkPhone(this.info.phone)){
                this.$message.error("手机号错误")
                return false
            }
            if(this.info.code.length!=4){
                this.$message.error("请填写验证码")
                return false
            }
            var _this = this
            this.$axios({
                method:"post",
                url:conf.api.loginByCode,
                data:this.info
            }).then(res=>{
                console.log(res)
                if(res.status==200){
                    if(res.data.code==1){
                        // 登陆成功
                        window.localStorage.setItem("token",res.data.data.uiid_info.uiid)
                        
                        _this.$store.commit("setLoginUser",res.data.data)
                        _this.$emit("loginBoxAct",{action:"hideLogin"})
                    }
                }
            }).catch(err=>{
                console.log(err)
            })

        },
        cancelLogin(){
            this.$emit("loginBoxAct",{action:"hideLogin"})
        }
    }
}
</script>

<style scoped>
.login-box{
    position: relative;
    width: 100vw;
    height: 100vh;
}

.login-form{
    width: 90%;
}
.login-title{
    width: 15vw;
}
.login-title-note{
    width:70vw
}


.input-box{
    margin: auto; 
    margin-top: 15px;
    width: 75vw;
    
}
.phone-box{
    margin-top: 5vh;
}
/* .code-box{

} */
.timer-box{
    position: absolute;
    width: 40%;
    background-color: #fff;
    height: 100%;
    right: 0;
    top: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 15px
}

.el-button{
    width: 100%;
    background-color: var(--main-color);
    border-radius: 10px;
    height: 50px;
    color: var(--black-color);
    font-size: 18px;
    font-weight: 700;
}
.dologin-box{
    margin-top: 30px;
}
.agree-box{
    color: #fff!important;
    font-size: 8px
}

.captcha-btn-box{
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 10px;

}
.captcha-cancel{
    background-color: var(--grey-color)
}
</style>