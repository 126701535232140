const conf = {
    api:{
        doLogin:"/api/dologin", 
        loginInfo:"/api/logininfo",

        lesson:"/api/lesson",
        lessonDetail:"/api/lessondetail",

        order:"/api/userorder",
        createOrder:"/api/createorder",
        getPrepay:"/api/getprepay",
        getPaySign:"/api/getpaysign",

        createCaptcha:"/api/captcha",
        sendPhoneCode:"/api/sendphonecode",
        loginByCode:"/api/loginByCode",
        loginByToken:"/api/getlogininfo",

        checksign:"/api/checksign",
        usersignup:"/api/usersignup", //报名，不是登录注册

        wxshare:"/api/wxshare",
        wxShareSuccess:"/api/wxsharesuccess",
        shareErCode:"/api/ercode",
        shareView:"/api/shareview",
        adv:"/api/adv"

    },
    qiniuPath:"nongren/0/",
    async getQiniuToken(axios,ext,table_word){
     
    
        const res = await axios.get(this.api.getQiniuToken+"?ext="+ext+"&table_word="+table_word);
 
        return res;
       
    },

    upFile(file,qiniu,data,fun){
  
        // const observable = qiniu.upload(file, this.qiniuPath+data.cover, data.token)
        const observable = qiniu.upload(file, data.key, data.token)
            const observer = {
                next(res){
                  // ...
                  console.log("next res",res)
                },
                error(err){
                  // ...
                  console.log("error:err",err)
                },
                complete(res){
                  // ...
                  console.log("complete res:",res)
                  fun(res)
                }
              }
           
            observable.subscribe(observer) // 上传开始
    },

    formData(timeStemp){
      if(timeStemp<1){
        return "";
      }
      var dt = new Date(timeStemp)
      var year = dt.getFullYear()
      var mon = dt.getMonth()
      var d = dt.getDate()
      var h = dt.getHours()
      var minu = dt.getMinutes()
      return year+"-"+mon+"-"+d+" "+h+":"+minu
    },
    checkPhone(str){
      var reg = /^1[3456789]\d{9}$/;
      // ^1  以1开头
      // [3456789] 第2位，使用原子表里的任意一个原子都可以
      // \d{9}$  第三位  朝后可以是任意数字  并且最后结尾必须是数字
      
      if(reg.test(str)){
        return true;
      }else{
        
        return false;
      }
    },

    // createOrder:"/api/createorder",
    createOrder(that,payInfo){
      var _this = this
      that.$axios.get(this.api.createOrder+"?code="+payInfo.code+"&lesson_id="+payInfo.lesson_id).then(res=>{
        if(res.status==200 && res.data.data){
          _this.getPrepay(that,payInfo,res.data.data)
        }else{
          that.$message.error("创建订单失败")
        }
      }).catch(err=>{
        console.log(err)
        that.$message.error("网络错误1")
      })
    },

    // getPrepay:"/api/getprepay",
    getPrepay(that,payInfo,order){
      var _this = this
      
      that.$axios(this.api.getPrepay+"?order_id="+order.id+"&code="+payInfo.code).then(res=>{
      
        if(res.status==200){

         
					var prepay_id=res.data.prepay_id;
         
          _this.getPaySign(that,order,prepay_id)
        }else{
          that.$message.error("拉起支付失败")
        }
      }).catch(err=>{
        console.log(err)
        that.$message.error("网络错误2")
      })
    },
    // getPaySign:"/api/getpaysign"
    getPaySign(that,order,prepay_id){
     
      var _this = this
      that.$axios.get(this.api.getPaySign+"?order_id="+order.id+"&prepay_id="+prepay_id).then(res=>{
        if(res.status==200 && res.data.code==1){
          _this.wxPay(that,res.data.data,prepay_id)
        }
      })
    },
    wxPay(that,order,prepay_id){
      console.log(order,prepay_id)
      /* eslint-disable */
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            "appId":"wxc87f75805a3f7a00",     //公众号ID，由商户传入     
            "timeStamp":order.create_time,//"1395712654",         //时间戳，自1970年以来的秒数     
            "nonceStr":order.order_num, //随机串     
            "package":"prepay_id="+prepay_id,     
            "signType":"MD5",         //微信签名方式：     
            "paySign":order.paySign, //微信签名 
            
          },
          function(res){
            if(res.err_msg == "get_brand_wcpay_request:ok" ){
            // 使用以上方式判断前端返回,微信团队郑重提示：
                  //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                  var url = "/api/wxowncallback?order_num="+order.order_num+"&uid="+that.$store.state.loginUser.id
                 
                  that.$axios.get(url)
            } 
        });
      /* eslint-disable */
       
    },
    // wxShare(conf,data){

      
    //   /* eslint-disable */
    //   wx.config({
    //     debug: true,
    //     appId: conf.appId,
    //     timestamp: conf.timestamp,
    //     nonceStr: conf.nonceStr,
    //     signature: conf.signature,
    //     jsApiList: ['onMenuShareAppMessage', 'onMenuShareTimeline','updateTimelineShareData','updateAppMessageShareData']
    //   });
	 
	  //   wx.ready(function() {
    //     wx.onMenuShareAppMessage({
    //       title: data.title,
    //       desc: data.desc,
    //       link: data.link,
    //       imgUrl: data.imgUrl,
    //       success: function() {
    //         // 分享成功的回调函数
    //         alert("ok")
    //       },
    //       cancel: function() {
    //         // 分享取消的回调函数
    //         alert("err")
    //       }
    //     });
		 
		//     wx.onMenuShareTimeline({
    //       title: data.title,
    //       link: data.link,
    //       imgUrl: data.imgUrl,
    //       success: function() {
    //         // 分享成功的回调函数
    //         alert("ok11")
    //       },
    //       cancel: function() {
    //         // 分享取消的回调函数
    //         alert("err11")
    //       }
		//     });

		//     //分享到朋友圈
	  //     wx.updateTimelineShareData({
    //       title: data.title,
    //       link: data.link,
    //       imgUrl: data.imgUrl,
    //       success: function (res) {
    //         // 分享成功
    //         console.log(res)
    //       }
	  //     })

	  //     wx.updateAppMessageShareData({ 
    //           title: data.title,
    //           desc: data.desc,
    //           link: data.link,
    //           imgUrl: data.imgUrl,
    //           success: function (res) {
    //           // 分享成功
    //           console.log(res)
    //           }
    //     })
    //   });
    // }
  
}

export default conf