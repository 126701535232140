<template>
  <div class="home">
    <!-- 轮播图 -->
      <div class="banner-box">
        <el-carousel :height="bannerHight" direction="horizontal" :autoplay="true" indicator-position="none">
          <el-carousel-item v-for="(item,k) in banner" :key="k">
            <img class="list-item-cover" :src="qiniuHost+item.cover" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 列表 -->
      <div class="list-box">


        <!-- <div class="list-item">
          <router-link to="/lessondetail">
            <img class="list-item-cover" src="@/assets/img/item-cover.png" />  
          </router-link>
          

          <div class="list-item-info flex-between">

            <div>
              <div class="item-title">
                一起上热门拿结果
              </div>
              <div class="flex-left">
                <div class="item-fee"> 
                  ￥ 499
                </div>
                <div class="item-sign-num">
                  &nbsp;·&nbsp;899人报名
                </div>
              </div>
              
            </div>
         
            <div>
              <div class="btn item-btn">
                立即报名
              </div>
            </div>

          </div>
        </div> -->

        <div v-for="(item,key) in info.list" :key="key" class="list-item">
          <router-link :to="'/lessondetail?id='+item.id">
            <img class="list-item-cover" :src="qiniuHost+item.cover" />   
          </router-link>
          

          <div class="list-item-info flex-between">

            <div>
              <div class="item-title">
                {{item.title}}
                <br/>
              </div>
              <div class="flex-left">
                <div class="item-fee"> 
                  ￥ {{item.real_fee}}
                </div>
                <!-- <div v-if="item.view_num>0" class="item-sign-num">
                  &nbsp;·&nbsp;{{formViewNum(item.view_num)}}人报名
                </div> -->
              </div>
              
            </div>
            <!-- 右侧按钮 -->
            <div>
              <div class="btn item-btn" @click="createOrder(item.id)">
                立即报名
              </div>
            </div>

          </div>
        </div>

      </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import conf from "@/assets/js/conf"
import wx from "weixin-js-sdk"
import {Base64} from "js-base64"
export default {
  name: 'HomeView',
  components: {
    // HelloWorld
  },
  data(){
    return {
      info:{
          list:[],
          total:0
      },
      loading:true,
      qiniuHost:"",
      banner:[],
      bannerHight:"75vw",
      
    }
  },
  mounted(){
  
    this.qiniuHost = process.env.VUE_APP_QNHOST




    this.getList()

    this.getBanner()
    this.getShareInfo()

    this.getShareInfo()

    var _this = this
    window.onresize=function(){
      _this.upBannerH()
    }
  },
  methods:{
    getList(){
        var _this = this
        this.$axios.get(conf.api.lesson+"?page="+this.info.page).then(res=>{
            console.log(res)
            if(res.status==200){
                _this.info = res.data
            }
            _this.loading=false
            
        }).catch(err=>{
        console.log(err)
        })
    },
    createOrder(lesson_id){
      this.$emit("gbAction",{act:"createOrder",lesson_id:lesson_id})
      
    },
    formViewNum(num){
            if(num>100000){
                num = num/10000
                return num.toFixed(2)+"万"
            }
            
            return num
    },
    getBanner(){
      this.$axios.get(conf.api.adv+"?type=banner").then(res=>{
        if(res.status==200){
          this.banner = res.data.list
          this.upBannerH()

        }
        
      }).catch(err=>{
        if(err){
          console.log("")
        }
      })
    },
    upBannerH(){
      console.log(window.innerWidth)
      if(this.banner.length>0){

          var _this = this
          var img = new Image()
          img.src = this.qiniuHost+this.banner[0].cover
          img.onload=function(){
              var screenW = window.innerWidth 
              if(screenW>800){
                screenW=800
              }
              var h = img.height * screenW/img.width
              _this.bannerHight=h+"px"
          }   
          
      }else{
          this.bannerHight="50px"
      }
            
    },

    getShareInfo(){
        var url=window.location.origin+this.$route.path
        console.log(url)
        var _this = this
        this.$axios.get(conf.api.wxshare+"?url="+Base64.encode(url)).then(res=>{
          
            var data={
                title:"【众鸣】普通人如何搞流量",
                desc: "我们都是普通人，但我们也想到抖音搞流量。普通人IP流量增长线上创业搞米",
                link: url,
                // imgUrl: "https://uieditor.ui.cn/nongren/1/57",
                imgUrl:_this.qiniuHost+_this.banner[0].cover,
            }
            _this.doWxShare(res.data.data,data)
        }).catch(err=>{
          if(err){
            console.log("")
          }
        })
  
    },
    doWxShare(conf,data){
  
        console.log("======wxShare=======",conf,data)
       
        wx.config({
          // debug: true,
          appId: conf.appId,
          timestamp: conf.timestamp,
          nonceStr: conf.nonceStr,
          signature: conf.signature,
          jsApiList: ['onMenuShareAppMessage', 'onMenuShareTimeline','updateTimelineShareData','updateAppMessageShareData']
        });
  
        wx.ready(function() {
          wx.onMenuShareAppMessage({
            title: data.title,
            desc: data.desc,
            link: data.link,
            imgUrl: data.imgUrl,
            success: function() {
              // 分享成功的回调函数
              alert("ok")
            },
            cancel: function() {
              // 分享取消的回调函数
              alert("err")
            }
          });
  
          wx.onMenuShareTimeline({
            title: data.title,
            link: data.link,
            imgUrl: data.imgUrl,
            success: function() {
              // 分享成功的回调函数
              alert("ok11")
            },
            cancel: function() {
              // 分享取消的回调函数
              alert("err11")
            }
          });
  
          //分享到朋友圈
          wx.updateTimelineShareData({
            title: data.title,
            link: data.link,
            imgUrl: data.imgUrl,
            success: function (res) {
              // 分享成功
              console.log(res)
            }
          })
  
          wx.updateAppMessageShareData({ 
                title: data.title,
                desc: data.desc,
                link: data.link,
                imgUrl: data.imgUrl,
                success: function (res) {
                // 分享成功
                console.log(res)
                }
          })
        });
    }
  }
}
</script>

<style scoped>

</style>
