import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
  {
    path: '/userlesson',
    name: 'userlesson',
    component: () => import(/* webpackChunkName: "about" */ '../views/UserLesson.vue')
  },
  {
    path: '/lessondetail',
    name: 'lessondetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/LessonDetails.vue')
  },

  {
    path: '/ucenter',
    name: 'ucenter',
    component: () => import(/* webpackChunkName: "about" */ '../views/UcenterView.vue')
  },
  {
    path: '/join',
    name: 'join',
   
    component: () => import(/* webpackChunkName: "about" */ '../views/JoinView.vue')
  },
  {
    path: '/joinform',
    name: 'joinform',
    
    component: () => import(/* webpackChunkName: "about" */ '../views/JoinformView.vue'),
   
  },
  {
    path: '/askframe',
    name: 'askframe',
    
    component: () => import(/* webpackChunkName: "about" */ '../views/AskFrame.vue'),
   
  },
  {
    path: '/test',
    name: 'test',
    
    component: () => import(/* webpackChunkName: "about" */ '../views/TestView.vue'),
   
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



export default router
