import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginUser:{
      id:0
    },
    wxShare:{
      appId:"",
      timestamp:"",
      nonceStr:"",
      signature:"",
      title:"【众鸣】我要搞流量",
      desc:"我们都是普通人，但我们也想到抖音搞流量。普通人IP流量增长线上创业搞米",
      link:"",
      imgUrl:""
    }
  },
  getters: {
  },
  mutations: {
    setLoginUser(state,layer){
      console.log("================sssssssssss",layer)
      state.loginUser = layer
    }
  },
  actions: {
  },
  modules: {
  }
})
